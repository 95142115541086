 <template>
    <div class="page-table haberTanim mainDiv listPage mb-30" id="affix-container">
        <div class="card-base baslikBg">
            <span>{{$t("src.views.apps.galeri.liste.title")}}</span>

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button
                        v-on:click="refreshPage()"
                        type="text"
                        style="margin-left: 15px !important"
                        icon="el-icon-refresh"
                        class="routeButton">
                    </el-button>
                </el-tooltip>

                <el-tooltip :content='$t("src.views.apps.genel.yeniKaydet")' :open-delay="500" placement="bottom">
                    <el-button
                        v-on:click="routeGaleri('Add Gallery')"
                        type="text"
                        style="margin-left: 15px !important"
                        icon="el-icon-plus">
                    </el-button>
                </el-tooltip>
            </div>
        </div>

        <!-- Loading true oldugunda loading textti yazılacak eğer false ise o zaman loadingState true değeri almıştır loadingState mesajı gösterilecek -->
        <div class="page-header card-base card-shadow--medium animated fadeInUp" v-loading="loading || loadingState"
            :element-loading-text='loading ? $t("src.views.apps.galeri.liste.loading") : $t("src.views.apps.galeri.liste.detailLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-col :span="24">
                <div>
                    <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9"
                        text-color="gray">
                        <el-radio-button label="1">
                            <i class="mdi mdi-image-filter-none mdi-24px" style="color: #13ce66; margin-right: 5px"></i>
                            <transition name="slide-fade">
                                <label v-if="selectIcon==='1'">{{$t("src.views.apps.galeri.liste.activeGallery")}}</label>
                            </transition>
                        </el-radio-button>
                        <el-radio-button label="0">
                            <i class="mdi mdi-image-filter-none mdi-24px" style="color: #ec205f; margin-right: 5px"></i>
                            <transition name="slide-fade">
                                <label v-if="selectIcon==='0'">{{$t("src.views.apps.galeri.liste.passiveGallery")}}</label>
                            </transition>
                        </el-radio-button>
                    </el-radio-group>
                </div>
            </el-col>

            <el-row style="overflow: hidden"  :gutter="10">
                <el-col :lg="4" :md="4" :sm="24" :xs="24" v-for="(element,index) in galeriList" :key="element.galeriID" :data-gecerliindex="index" class="mt-10">
                    <div class="galeriListeCerceve">
                        <div class="galeriListeButonAlani">
                            <el-tooltip class="item" effect="dark" :content='$t("src.views.apps.genel.duzenle")' placement="top">
                                <button  v-on:click="routeGaleri('Update Gallery',element)"><i class="mdi mdi-pen"></i></button>
                            </el-tooltip>
                            <el-tooltip v-if="selectIcon === '1'" class="item" effect="dark" :content='$t("src.views.apps.genel.kaldir")' placement="top">
                                <button @click="galeriDurumDegis(element,index,galeriList,'0')"><i class="mdi mdi-trash-can"></i></button>
                            </el-tooltip>
                            <el-tooltip v-if="selectIcon === '0'" class="item" effect="dark" :content='$t("src.views.apps.genel.tanimla")' placement="top">
                                <button @click="galeriDurumDegis(element,index,galeriList,'1')"><i class="mdi mdi-play"></i></button>
                            </el-tooltip>
                        </div>

                        <img v-if="element.resim !== '0'" :src="imagepath+element.resim" alt="" class="img-fluid"/>
                        <img v-else src="../../../../public/static/images/gallery/default.png" alt="" class="img-fluid"/>
                        <div class="text-center">
                            {{element.baslik}}
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>

        
        <el-dialog :title='$t("src.views.apps.slider.liste.uyari")' :visible.sync="deleteDialogVisible" width="30%" center>
            <span>'{{selection.anaBaslik}}' {{$t('src.views.apps.slider.liste.slidertaslak')}}</span>
            <span slot="footer" class="dialog-footer">
                <el-button icon="el-icon-check"
                type="success"
                size="mini"
                @click="galeriDurumDegis(selection,selectionIndex,galeriList,'0'), deleteDialogVisible = false"></el-button>
                <el-button type="danger" size="mini" icon="el-icon-close"
                    @click="deleteDialogVisible = false"></el-button>
            </span>
        </el-dialog>

        <el-dialog :title='$t("src.views.apps.slider.liste.uyari")' :visible.sync="addDialogVisible" width="30%" center>
            <span>'{{selection.anaBaslik}}' {{$t('src.views.apps.slider.liste.slideradd')}}</span>
            <span slot="footer" class="dialog-footer">
                <el-button icon="el-icon-check" type="success" size="mini" @click="galeriDurumDegis(selection,selectionIndex,galeriList,'1'), addDialogVisible = false"></el-button>
                <el-button type="danger" size="mini" icon="el-icon-close" @click="addDialogVisible = false"></el-button>
            </span>
        </el-dialog>
    </div>

</template>

<script>
    import galeriService from '../../../WSProvider/GaleriService'
    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'


    import jwt_decode from "jwt-decode"
    let userDataBGSurec = "";

    export default {
        name: "galerilistesi",
        data() {
            return {
                total: 0, // galeriden tum resimleri cekmek için önce kac resim var istek atıp sayısını total değişkeninde tutuyoruz
                loadingState: false,
                loading: false,
                selectIcon: '1',
                radio: '1',
                imagepath: galeriService.imagePath,
                galeriList: [],
                deleteDialogVisible: false,
                addDialogVisible: false,
                selection: '',
                selectionIndex: '',
            }
        },
        mounted() {
            let self = this;
            this.getGaleriCount();

            EventBus.$on('galeriList', function (bool) {
                self.getGaleriCount();
            });

        },
        methods: {
            refreshPage(){
                this.loadingState = false;
                this.loading = false;
                this.getGaleriList();
            },

            handleChange(event) {
                this.selectIcon = event;
                this.getGaleriList();
            },
            
            getGaleriList() {
                this.loading = true;
                galeriService.galeriListeleDurumIle(this.selectIcon, 0, this.total).then((response) => {
                    if (response.status == 200) {
                        localStorage.setItem("userDataBGSurec", response.token)
                        this.galeriList = response.data;
                    }
                    this.loading = false;
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }else if(error.status == 404){
                            this.galeriList = [];
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                    }else{
                        //notification.Status(503, this)
                    }
                    this.loading = false;
                });
            },

            getGaleriCount() {
                galeriService.galeriSayisi(this.selectIcon).then((response) => {
                    if (response.status == 200) {
                        localStorage.setItem("userDataBGSurec", response.token)
                        this.total = response.data;
                        this.getGaleriList();
                    }
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }else if(error.status == 404){
                            this.total = 0;
                            this.galeriList = [];
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                    }else{
                        //notification.Status(503, this)
                    }
                });
            },

            galeriDurumDegis(selection, index, list, durum) {
                this.loadingState = true;
                galeriService.galeriDurumDegis(selection.galeriID, durum).then(response => {
                    if(response.status == 200){
                        list.splice(index, 1);
                        localStorage.setItem("userDataBGSurec", response.token)
                    }
                    this.loadingState = false;
                    notification.Status("success", this, response.msg);
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                    }else{
                        //notification.Status(503, this)
                    }
                    this.loadingState = false;
                });               
            },

            routeGaleri(name, scope) {
                if (scope) {
                    this.$store.commit('changeGaleriUpdateData', scope);
                }
                functions.routeSayfa(name, this);
            }
        }
    }

</script>


<style scoped lang="scss">
    .img-fluid {
        height: auto;
        max-width: 100%;
    }


    .galeriListeCerceve {
        box-shadow: 0 8px 16px 0 rgba(40, 40, 90, .09), 0 3px 6px 0 rgba(0, 0, 0, .065);
        padding: 5px;
        margin-bottom: 20px;
        background-color: #fff;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        transition: all .5s;

        .galeriListeButonAlani {
            position: absolute;
            height: calc(100% - 10px);
            width: calc(100% - 10px);
            left: 5px;
            top: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 300ms ease-in all;

            button {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                margin: 0 10px;
                background: #ffffff;
                color: #4b5970f0;
                line-height: 40px;
                font-size: 24px !important;
                outline: none !important;
                border: 1px solid #4b5970f0 !important;
                box-shadow: 0 8px 16px 0 rgba(40, 40, 90, .09), 0 3px 6px 0 rgba(0, 0, 0, .065);
                opacity: 0;
                transition: 200ms ease-in all;
                cursor: pointer;
            }
        }

        &:hover {
            box-shadow: none;
            transform: translateY(3px);

            .galeriListeButonAlani button {
                opacity: 1;
            }
        }
    }
</style>

